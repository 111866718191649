import React, {Suspense, lazy, useState, useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import Header from "./elements/Headers";
import CatalogueIcon from "./elements/CatalogueIcon";
import Loading from "./elements/Loading";
import BookOnlineContainer from './main/BookOnlineContainer';
const TrustUs = lazy(() => import('./main/TrustUs'));
const ShowContainer = lazy(() => import('./main/ShowContainer'));
const Footer = lazy(() => import('./elements/Footer'));


function Main() {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    return (
        <>
            <Helmet>
                <title>LOCATION de MATERIEL - Ozlaloc RENNES</title>

            </Helmet>
            {!isMounted ? (
                <Loading/>
            ) : (
                <>
                    <Header/>
                    <section className="mt-8 mb-12 lg:mb-0">
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="text-2xl lg:text-5xl font-bold">LOCATION DE MATÉRIEL</h1>
                            <h2 className="text-xs lg:text-md font-semibold text-center mx-2 lg:mx-auto opacity-75 hidden md:block">
                                Osez la location de matériel dans nos 2 agences situées à proximité de Rennes à
                                Nouvoitou
                                et Liffré
                            </h2>
                            <h2 className="text-xs font-semibold text-center mx-2  opacity-75 md:hidden block">
                            Osez la location de matériel à proximité de Rennes à Nouvoitou et Liffré

                    </h2>
                    {/* <div className="text-lg flex">
                        <div className="p-2 justify-center items-center flex">
                            <AcceleratingCounter />
                        </div>
                        <p className="my-auto">Équipements disponibles</p>
                    </div>*/}
                    <picture>
                        <img
                            srcSet="
      https://assets.ozlaloc.fr/autre/presentation/presentation_200x94.webp 200w,
      https://assets.ozlaloc.fr/autre/presentation/presentation_452x213.webp 452w,
      https://assets.ozlaloc.fr/autre/presentation/presentation_663x313.webp 663w,
      https://assets.ozlaloc.fr/autre/presentation/presentation_795x375.webp 795w,
      https://assets.ozlaloc.fr/autre/presentation/presentation_822x388.webp 822w"
                            sizes="(max-width: 200px) 160px,
           (max-width: 452px) 380px,
           (max-width: 663px) 580px,
           (max-width: 795px) 730px,
           822px"
                            title={'Ozlaloc Location louez du matériel'}
                            src="https://assets.ozlaloc.fr/autre/presentation/presentation_822x388.webp"
                            alt="Louez du matériel"
                            className="w-full h-auto p-4 rounded-lg mt-2"
                            width={822}
                            height={388}
                        />
                    </picture>
                    <h2 className="text-xs lg:text-md font-semibold text-center mx-2 lg:mx-auto opacity-75 hidden md:block">
                        Particuliers & professionnels, louez du matériel pour bricoler, jardiner et faire la fête !
                    </h2>
                    <h2 className="text-xs font-semibold text-center mx-2  opacity-75 md:hidden block">
                        Louez du matériel pour bricoler, jardiner et faire la fête !
                    </h2>
                </div>

                <div className="w-full mx-auto">
                    <div className="lg:flex justify-center lg:justify-between mx-auto lg:mx-40 ">

                        <CatalogueIcon
                            name={'bricolage'}
                            imgpresentation={'https://assets.ozlaloc.fr/autre/img/bricolage.webp'}
                            linkid={'bricolage'}
                            fulldesc={`
                    <div class="space-y-2 text-sm">
                      <p class="font-semibold">Ozlaloc vous accompagne dans vos travaux grâce à un service de location de matériel de bricolage, BTP, gros œuvre et nettoyage. Louez du matériel au meilleur prix, à la journée, au week-end (-30%), ou à la semaine (-40%).</p>
                    
                      <p>Vous trouverez dans nos deux agences situées près de Rennes en Ille-et-Vilaine, tous les consommables nécessaires au bon fonctionnement de vos outils.</p>
                    
                      <ul class="list-disc pl-3">
                        <li class="font-semibold">Tarifs professionnels, contactez-nous</li>
                        <li class="font-semibold">Ozlaloc propose un service de livraison pour les mini pelles et le gros outillage.</li>
                        <li class="font-semibold">Une caution par empreinte bancaire est obligatoire.</li>
                        <li class="font-semibold">Les agences de Nouvoitou et Liffré sont fermées le mercredi. Vous pouvez récupérer votre location le mardi soir et la restituer le jeudi matin (tarif 1 journée).</li>
                      </ul>
                    </div>

                    `}
                            img={'https://assets.ozlaloc.fr/univers/bricolage.svg'}
                            desc={'Ponceuse, burineur, scie, mini pelle,'}
                            desc2={'remorque, plaque vibrante, échafaudage, disqueuse'}
                            descmobile1={'Ponceuse, burineur, scie, mini pelle,'}
                            descmobile2={'remorque, plaque vibrante, échafaudage, disqueuse'}
                            url={'https://www.canva.com/design/DAFdQWDI7vk/dtzU9ArgfqCm1J79TfpOnA/view?utm_content=DAFdQWDI7vk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'}
                        />
                        <CatalogueIcon
                            name={'évènementiel'}
                            fulldesc={`
                 <div class=" space-y-2 text-sm">
                   <p class="font-semibold">Ozlaloc vous accompagne dans tous vos projets événementiels privés :</p>
                   <ul class="list-disc pl-6">
                     <li>Mariage, anniversaire, EVG, EVJF, cousinade, ...</li>
                   </ul>
                 
                   <p class="font-semibold">Et événements professionnels :</p>
                   <ul class="list-disc pl-6">
                     <li>Séminaire, portes ouvertes, salon, forum, réunion d'entreprise, remise de médaille ...</li>
                   </ul>
                 
                   <p>Faites confiance à un professionnel qui vous propose un projet tout inclus ! Nous serons à votre écoute pour faire de ce moment une réussite.</p>
                 
                   <p>Livraison avec installation de tente de réception et barnum certifiés anti-feu M2.</p>
                   <p>Location du mobilier, des nappes et de la vaisselle.</p>
                   <p>Location de pack sonorisation et lumière.</p>
                 
                   <p class="font-semibold">Conseils et mise en place de vos espaces :</p>
                   <ul class="list-disc pl-6">
                     <li>Pose de parquet, scène et praticable, décoration et mise en lumière.</li>
                   </ul>
                 
                   <p class="font-semibold">Mise en relation et négociation auprès des prestataires événementiels :</p>
                   <ul class="list-disc pl-6">
                     <li>Traiteur, DJ, décoration, machine à effets, techniques audiovisuelles ...</li>
                   </ul>
                 
                   <p class="font-semibold">Les plus :</p>
                   <ul class="list-disc pl-6">
                     <li>Location de château gonflable, location de tireuse à bière, location de borne à selfie ...</li>
                   </ul>
                 
                   <ul class="list-disc pl-6">
                     <li>Vous  souhaitez un RDV ! Chez vous ou dans nos agences nos conseillers vous accompagnent sur vos projets</li>
                     <li>Tarifs professionnels, contactez-nous</li>
                     <li>Caution par empreinte bancaire obligatoire</li>
                   </ul>
                 </div>


                    `}

                            linkid={'evenementiel'}
                            imgpresentation={'https://assets.ozlaloc.fr/qui-sommes-nous/Qui-sommes-nous-3.webp'}
                            img={'https://assets.ozlaloc.fr/univers/evenementiel.svg'}
                            desc={'Barnum, mange-debout, tente réception, parquet,  sonorisation, chaise,'}
                            desc2={'banc, vaisselle, borne à selfie, éclairage, château gonflable, table'}
                            descmobile1={'Barnum, tente réception, parquet, mobilier, sonorisation,'}
                            descmobile2={'vaisselle, borne à selfie, château gonflable'}

                            url={'https://www.canva.com/design/DAFdbxzHJIY/E2CHUGUCXIdSCRlqyCzWYA/view?utm_content=DAFdbxzHJIY&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'}
                        />
                        <CatalogueIcon
                            name={'jardinage'}
                            fulldesc={`
                   <div class="space-y-2 text-sm">
                     <p class="font-semibold">Ozlaloc vous accompagne dans l'aménagement de vos espaces verts grâce à un service de location de matériel de jardinage, motoculture, mini pelle et nettoyage. Louez du matériel au meilleur prix, à la journée, au week-end (-30%), ou à la semaine (-40%).</p>
                   
                     <p>Vous trouverez également dans nos deux agences situées près de Rennes en Ille-et-Vilaine, tous les consommables nécessaires au bon fonctionnement de votre outil.</p>
                   
                     <ul class="list-disc pl-3">
                       <li class="font-semibold">Tarifs professionnels, contactez-nous</li>
                       <li class="font-semibold">Ozlaloc propose un service de livraison pour les motoculteurs et les gros outillages.</li>
                       <li class="font-semibold">Une caution par empreinte bancaire est obligatoire.</li>
                       <li class="font-semibold">Les agences de Nouvoitou et Liffré sont fermées le mercredi. Vous pouvez récupérer votre location le mardi soir et la restituer le jeudi matin (tarif 1 journée).</li>
                     </ul>
                   </div>

                    `}

                            imgpresentation={'https://assets.ozlaloc.fr/autre/img/jardinage.webp'}
                            linkid={'jardinage-motoculture'}
                            img={'https://assets.ozlaloc.fr/univers/jardinage.svg'}
                            desc={'Motoculteur, broyeur, taille haie, rogneuse,'}
                            desc2={'pulvérisateur, tarière, fendeuse, débroussailleuse'}
                            descmobile1={'Motoculteur, broyeur, taille haie, rogneuse,'}
                            descmobile2={'pulvérisateur, tarière, fendeuse, débroussailleuse'}
                            url={'https://www.canva.com/design/DAFXMJSiEas/CMp6DI1Y5eBRRGeIjUf35w/view?utm_content=DAFXMJSiEas&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink'}
                        />
                    </div>
                </div>

            </section>

            <Suspense fallback={<Loading/>}>
                <BookOnlineContainer/>
            </Suspense>
            <Suspense fallback={<Loading/>}>
                <ShowContainer/>
            </Suspense>
                    <Suspense fallback={<Loading/>}>
                        <TrustUs/>
                    </Suspense>
            <Suspense fallback={<Loading/>}>
                <Footer/>
            </Suspense>
                </>
                )}
        </>

    );
}

export default Main;
